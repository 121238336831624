/*!
 * @package   yii2-nav-x
 * @author    Kartik Visweswaran <kartikv2@gmail.com>
 * @copyright Copyright &copy; Kartik Visweswaran, Krajee.com, 2014 - 2017
 * @version   1.2.1
 *
 * Nav X styling and enhancements for Bootstrap.
 * Built for Yii Framework 2.0
 * Author: Kartik Visweswaran
 * For more Yii related demos visit http://demos.krajee.com
 */
@media (max-width: 767px) {
    .navbar-nav .open .open .dropdown-menu > li > a,
    .navbar-nav .open .open .dropdown-menu .dropdown-header {
        padding-left: 35px;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > .open > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .open > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .open > a:focus {
        color: #555;
        background-color: #e7e7e7;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .open > a,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .open > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .open > a:focus {
        color: #fff;
        background-color: #080808;
    }
}
